import { useEffect } from 'react';
import { CreateContractDto } from '../api/contract/type';
import { useMyWages } from '../hooks/wage';
import { ContractTable } from './ContractTable';
import { TextField } from './TextField';
interface ContractWageAddTableProps {
  contractState: CreateContractDto;
  setContractState: (contractState: CreateContractDto) => void;
  companyId: number;
}

export default function ContractWageAddTable(
  contractWageTableProps: ContractWageAddTableProps
) {
  const { contractState, setContractState, companyId } = contractWageTableProps;
  const { hourPay, regularHourPay } = contractState;
  const { data: wages } = useMyWages();

  useEffect(() => {
    if (wages?.length && companyId) {
      const hourPay = wages.filter((w) => w.companyId === companyId)[0]?.price;
      setContractState({ ...contractState, hourPay });
    }
  }, [wages, companyId]);

  return (
    <>
      <div className="">
        <div className=" grid h-64 w-full grid-cols-6  divide-y divide-x rounded-md border">
          <ContractTable title="급여 구분" desc=" 시급" />
          <div className="grid place-items-center bg-sub-3 text-15 text-gray-700">
            <p className="after:ml-0.5 after:text-brand-1 after:content-['*']">
              기본시급
            </p>
          </div>
          <div className="col-span-5 grid grid-cols-2 content-center items-center gap-2 px-4 ">
            <TextField
              type="number"
              placeholder="금액을 입력해주세요."
              value={hourPay}
              onChange={(e) =>
                setContractState({
                  ...contractState,
                  hourPay: +e.target.value,
                })
              }
            />
            <p>원</p>
          </div>
          <ContractTable
            title="인건비 구성"
            desc={`통상시급 ${regularHourPay.toLocaleString()}원 기타수당 ${
              hourPay - regularHourPay > 0 ? hourPay - regularHourPay : 0
            }원`}
          />
          <ContractTable
            title="근무시간"
            desc="기본시급x(근무시간–공제시간)=1일임금"
          />
        </div>
      </div>
    </>
  );
}
