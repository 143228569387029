import { WorkDateType, WorkTimeType } from './types';

export const SCV = {
  companyName: '(주) 원드림',
  phone: '02-558-1195',
  address: '경기도 성남시 분당구 성남대로916번길 11, 4층 416호(야탑동)',
  user: '김 기 연',
};

export const ITEMS_PER_PAGE_SM = 10;
export const ITEMS_PER_PAGE_MD = 20;
export const ITEMS_PER_PAGE_LG = 50;

export const HOUR_PAY = 10030;

export const KOREAN_WORK_DATE_TYPE = {
  [WorkDateType.ALL]: '전체',
  [WorkDateType.WEEKDAY]: '평일',
  [WorkDateType.WEEKEND]: '주말',
};

export const KOREAN_WORK_TIME_TYPE = {
  [WorkTimeType.MORNING]: '오전',
  [WorkTimeType.AFTERNOON]: '오후',
  [WorkTimeType.FULLTIME]: '풀타임',
};

export const WORK_FORM = {
  서빙: '서빙',
  주방: '주방',
  주차: '주차',
  편의점: '편의점',
  사무업무: '사무업무',
  카페: '카페',
  호텔: '호텔',
  음식점: '음식점',
  단순노무: '단순노무',
  기타: '기타',
};
